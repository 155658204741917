import React, { useState, useMemo, useEffect } from "react";
import i18n from "i18next";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import {
  getUuid,
  convertCustodianHistoryApiDateFormatToUIFormat,
  tickerTypes,
  getSanitizedExchangeRate,
  formatNumberAsCurrency,
  fetchTickerDetails,
  showToastTip,
  getExchangeRate,
  getCustodianHistoryApiFormattedDateString,
  updateCustodianRate,
  deleteCustodianRate,
  getKuberaDateString,
  getTickerUsingId,
  getTickerUsingShortName,
  updateCustodian,
  accountLinkingService,
  fetchCustodianDetails,
  tickerSubTypes
} from "@kubera/common";

import CustodianParentDetailsComponent from "./CustodianParentDetailsComponent";
import NumberInput from "components/inputs/NumberInput";
import {
  GridData,
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  CurrencyCellData,
  cellType
} from "components/grid/GridDataModel";
import CostbasisReturnsComponent from "./CostbasisReturnsComponent";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import CustodianPVSTHistoryComponent from "./CustodianPVSTHistoryComponent";
import ExchangeRateChangeDialog from "components/grid/ExchangeRateChangeDialog";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ParentDetails = styled(CustodianParentDetailsComponent)`
  margin-top: 32px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  margin-bottom: 16px;
`;

const QtyTitle = styled.div`
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  margin-bottom: 5px;
`;

const TickerInput = styled(NumberInput)`
  padding: 18px 14px 15px;
  box-sizing: border-box;
  width: 100%;
  border: ${props => (props.isError ? "1px solid #ff0000" : "1px solid rgba(0, 0, 0, 0.4)")};
  outline: 0;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  margin-bottom: 20px;
`;

const ExchangeRateCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px;
  padding: 3px 5px 3px 5px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 11px;
  font-feature-settings: "ss01" on;
  color: rgba(36, 36, 36, 0.6);
  cursor: pointer;
`;

const ExchangeCurrencies = styled.div``;

const ExchangeRate = styled.div``;

const Grid = styled(GridComponentWrapper)`
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
`;

const GridContainer = styled.div`
  margin-top: 3px;
  border-top: 0;
`;

const LastUpdate = styled.div`
  width: fit-content;
  margin-top: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

const Footer = styled.div`
  white-space: pre-wrap;
  font-size: 13px;
  line-height: 19.5px;
  color: #000000cc;
  margin-top: 26px;

  a {
    color: inherit;
  }
`;

const gridOptions = {
  getRowStyle: () => {
    return {
      height: "49px"
    };
  },
  getSectionStyle: () => {
    return {
      [`min-height`]: "50px",
      border: "1px solid #00000066"
    };
  }
};

const CustodianPVSTDetailsComponent = ({ className, ...otherProps }) => {
  const dispatch = useDispatch();

  const { details, detailsInfo, portfolioCurrency, custodianId, sectionId, isReadOnly, parentId, history } = otherProps;

  const rate = detailsInfo?.rate;

  const rateParsed = useMemo(() => {
    if (rate) return JSON.parse(rate);
    return null;
  }, [rate]);

  const handleChange = newGridData => {
    setGridData(newGridData);
  };

  const getGridData = currency => {
    var rows = [];

    const row = getEmptyRow("1");
    row.onUpdateDelay = 3000;
    row.id = custodianId;

    rows.push(row);

    const section = getEmptySection(0, "1");
    section.rows = rows;

    const sheet = getEmptySheet("1");
    sheet.sections = [section];

    const gridData = new GridData(currency, [sheet]);
    gridData.forceShowSheetsTitles = false;
    return gridData;
  };

  const getEmptySheet = sortKey => {
    return new GridSheetData(getUuid(), sortKey, null, []);
  };

  const getEmptySection = (forIndex, sortKey) => {
    const costColumn = new GridColumnData("Price", true, isReadOnly === false, false);
    const sectionData = new GridSectionData(
      getUuid(),
      sortKey,
      "Section " + (forIndex + 1),
      [],
      [costColumn],
      0,
      0,
      false
    );
    sectionData.showFooter = false;
    sectionData.showHeader = false;
    return sectionData;
  };

  const getEmptyRow = sortKey => {
    const costCell = new CurrencyCellData(
      cellType.CURRENCY,
      "Price",
      rateParsed?.p,
      rateParsed?.t ? getTickerUsingId(rateParsed?.t).shortName : portfolioCurrency
    );
    costCell.enterEditModeOnClick = true;
    costCell.hideCellFocusHighlight = true;
    costCell.useRateFromExchangeRateDetails = true;
    costCell.supportedTickerTypes = [tickerTypes.FIAT, tickerTypes.CRYPTO];
    costCell.unsupportedTickerSubTypes = [tickerSubTypes.PRECIOUS_METALS];
    costCell.textAlignment = "left";
    costCell.showDecimal = true;
    costCell.decimalPlaces = 4;

    costCell.getAccessoryView = getAccessoryView;

    const cells = [costCell];
    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 0, false, () => {
      return true;
    });
    rowData.showHint = false;
    rowData.getContextMenuItems = (row, rowIndex) => {
      return null;
    };
    return rowData;
  };

  const updateRate = (updatedRow, index, uuid, date = new Date()) => {
    const updatedCell = updatedRow.cells[index];
    return new Promise((resolve, reject) => {
      dispatch(
        updateCustodianRate(
          detailsInfo,
          {
            id: uuid,
            date: getKuberaDateString(date),
            rate: JSON.stringify({
              u: 1,
              t: updatedCell.valueTickerId
                ? updatedCell.valueTickerId
                : getTickerUsingShortName(updatedCell.currency).id,
              p: updatedCell.value
            }),
            valueExchangeRate: updatedCell.exchangeRateDetails
          },
          uuid,
          updatedCustodian => {
            resolve(updatedCustodian);
          }
        )
      );
    });
  };

  const deleteRate = (uuid, fetchDetails = 0) => {
    dispatch(
      deleteCustodianRate(detailsInfo, uuid, () => {
        if (fetchDetails === 1) {
          dispatch(fetchCustodianDetails(custodianId, 1));
        }
      })
    );
  };

  const handleRowUpdate = (sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit) => {
    const cell = updatedRow.cells[0];
    cell.exchangeRateDate = null;
    cell.setExchangeRateDetails(portfolioCurrency, getExchangeRate(cell.currency, portfolioCurrency));
    const rateEntryForToday = details.rate && details.rate.find(data => data.date === getKuberaDateString(new Date()));
    const rateEntryForTodayId = rateEntryForToday ? rateEntryForToday.id : undefined;
    updateRate(updatedRow, 0, rateEntryForTodayId, undefined);
  };

  const updateGridRow = (newRow, sheetIndex, sectionIndex, rowIndex) => {
    const newGridData = gridData;
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = newRow;
    setGridData(newGridData);
  };

  const handleCellInvalidTickerAdded = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
    const row = gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    const cell = row.cells[cellIndex];

    if (!cell.invalidInputText === false && cell.loading === false) {
      cell.loading = true;
      updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);

      dispatch(
        fetchTickerDetails(
          cell.invalidInputText,
          undefined,
          result => {
            const row = gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
            const cell = row.cells[cellIndex];

            cell.loading = false;
            if (!result === true) {
              dispatch(showToastTip("TIP", i18n.t("invalidCashflowTickerError"), null, 10000));
              updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
              return;
            }

            const validTickers = result.tickers.filter(
              ticker =>
                cell.supportedTickerTypes.includes(ticker.info.type) &&
                !cell.unsupportedTickerSubTypes.includes(ticker.info.subType)
            );
            if (validTickers.length > 1) {
              cell.tickerInfo = validTickers;
              updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
            } else if (validTickers.length === 1) {
              cell.exchangeRateDetails = result.exchangeRateDetails;
              cell.currency = result.tickerShortName;
              cell.invalidInputText = null;

              const newRow = row.clone();
              updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex);
              handleRowUpdate(sheetIndex, sectionIndex, rowIndex, newRow, false);
            } else {
              const row = gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
              const cell = row.cells[cellIndex];
              cell.loading = false;
              updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
              dispatch(showToastTip("TIP", i18n.t("invalidCashflowTickerError"), null, 10000));
            }
          },
          error => {
            const row = gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
            const cell = row.cells[cellIndex];
            cell.loading = false;
            updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
            dispatch(showToastTip("TIP", i18n.t("tickerFetchFailure"), null, 10000));
          }
        )
      );
    }
  };

  const getNewestCostHistoryDate = () => {
    if (!history) return null;
    var historyDate = CostbasisReturnsComponent.getNewestCostHistoryDate(history);
    if (history.length === 0 && detailsInfo.cost !== null && detailsInfo.cost !== undefined) {
      historyDate = getCustodianHistoryApiFormattedDateString(new Date().getTime());
    }
    return historyDate ? convertCustodianHistoryApiDateFormatToUIFormat(historyDate) : null;
  };

  const getNewestHistoryDate = () => {
    if (!details === true || !details.rate === true || !details.rate.length === 0) {
      return null;
    }
    var result = null;
    for (const item of details.rate) {
      if (!result === true) {
        result = item;
      } else if (item.date.localeCompare(result.date) > 0) {
        result = item;
      }
    }
    if (!result === true) {
      return null;
    }
    return convertCustodianHistoryApiDateFormatToUIFormat(result.date);
  };

  const handleLastUpdateClick = e => {
    setShowHistoryDetailsDialog(true);
  };

  const handleHistoryDetailsDialogOnDismiss = e => {
    setShowHistoryDetailsDialog(false);
  };

  const handleRateCellClick = (e, cellData) => {
    if (isReadOnly === true) {
      return;
    }
    setShowRateCellDialog(true);
    setRateCellDialogData(cellData);
  };

  const getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
    const costGridCell = gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[0];

    if (portfolioCurrency === costGridCell.currency) {
      return null;
    }

    const cellData = {
      exchangeRateDate: new Date(getNewestCostHistoryDate()),
      useRateFromExchangeRateDetails: true,
      exchangeRateDetails: `{"tickerId":${rateParsed?.t},"rate":${rateParsed?.p}}`,
      fromCurrency: costGridCell.currency,
      toCurrency: portfolioCurrency,
      rate: costGridCell.getCellExchangeRate(portfolioCurrency),
      sanitizedRate: getSanitizedExchangeRate(costGridCell.getCellExchangeRate(portfolioCurrency), portfolioCurrency),
      sheetIndex: sheetIndex,
      sectionIndex: sectionIndex,
      rowIndex: rowIndex,
      cellIndex: 0
    };

    return (
      <ExchangeRateCell onClick={e => handleRateCellClick(e, cellData)}>
        <ExchangeCurrencies>{`${cellData.fromCurrency.replace(".CC", "")}/${cellData.toCurrency.replace(
          ".CC",
          ""
        )}`}</ExchangeCurrencies>
        <ExchangeRate>{formatNumberAsCurrency(cellData.sanitizedRate, portfolioCurrency)}</ExchangeRate>
      </ExchangeRateCell>
    );
  };

  const handleRateCellDialogOnChange = rate => {
    const rateData = rateCellDialogData;
    const newGridData = gridData;

    const row = newGridData.sheets[rateData.sheetIndex].sections[rateData.sectionIndex].rows[rateData.rowIndex];
    const cell = row.cells[rateData.cellIndex];
    cell.setExchangeRateDetails(portfolioCurrency, rate);
    cell.getAccessoryView = getAccessoryView;
    newGridData.sheets[rateData.sheetIndex].sections[rateData.sectionIndex].rows[rateData.rowIndex] = row.clone();

    setGridData(newGridData);
  };

  const handleRateCellDialogOnDismiss = () => {
    setShowRateCellDialog(false);
    setRateCellDialogData(null);
  };

  const handleQuantityUpdate = (e, value) => {
    dispatch(updateCustodian(false, custodianId, { value: value }, true));
  };

  const [gridData, setGridData] = useState(getGridData(portfolioCurrency));
  const [showHistoryDetailsDialog, setShowHistoryDetailsDialog] = useState(false);
  const [showRateCellDialog, setShowRateCellDialog] = useState(false);
  const [rateCellDialogData, setRateCellDialogData] = useState(null);

  useEffect(() => {
    setGridData(getGridData(portfolioCurrency));
    // eslint-disable-next-line
  }, [rateParsed]);

  if (!details) return null;

  return (
    <Container className={className}>
      {detailsInfo?.srcName && <Title>{detailsInfo.srcName}</Title>}
      <QtyTitle>{i18n.t("custodianDetails.quantity")}</QtyTitle>
      <TickerInput placeholder={i18n.t("quantity")} value={detailsInfo.value} onChange={handleQuantityUpdate} />
      <QtyTitle>{i18n.t("price").toUpperCase()}</QtyTitle>
      <GridContainer>
        <Grid
          gridData={gridData}
          getEmptyRow={getEmptyRow}
          onChange={handleChange}
          onRowUpdate={handleRowUpdate}
          onCellInvalidTickerAdded={handleCellInvalidTickerAdded}
          gridOptions={gridOptions}
        />
      </GridContainer>
      {!getNewestHistoryDate() === false && (
        <LastUpdate onClick={handleLastUpdateClick}>{i18n.t("lastUpdate") + ": " + getNewestHistoryDate()}</LastUpdate>
      )}
      {detailsInfo?.hidden === 0 && parentId && <ParentDetails {...otherProps} />}
      {detailsInfo.linkType === accountLinkingService.IN_HOUSE_OAUTH && (
        <Footer
          dangerouslySetInnerHTML={{
            __html: i18n.t("custodianDetails.pvstFooter")
          }}
        />
      )}
      {showHistoryDetailsDialog === true && (
        <CustodianPVSTHistoryComponent
          details={details}
          currency={portfolioCurrency}
          custodianId={custodianId}
          sectionId={sectionId}
          onDismiss={handleHistoryDetailsDialogOnDismiss}
          isReadOnly={isReadOnly}
          updateRate={updateRate}
          deleteRate={deleteRate}
          history={details.history}
        />
      )}
      {!showRateCellDialog === false && (
        <ExchangeRateChangeDialog
          rateData={rateCellDialogData}
          onRateChange={handleRateCellDialogOnChange}
          onDismiss={handleRateCellDialogOnDismiss}
          readOnly
        />
      )}
    </Container>
  );
};

export default CustodianPVSTDetailsComponent;
