import React from "react";
import styled from "styled-components";
import {
  getChartStyleFromChartId,
  chartStyle,
  parseParams,
  chartKeyParams,
  recapReportValueSelector,
  store,
  getChartLabelFromId,
  getLineChartTitle,
  recapReportNameSelector,
  chartContent,
  recapReportNodeSelector,
  chartTimeRange,
  recapChartOptions,
  useHistory
} from "@kubera/common";
import i18n from "locale/i18n";
import Checkbox from "components/inputs/Checkbox";
import { routes } from "routes";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DefaultTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  margin-top: 25px;
  margin-bottom: 0px;
`;
const DefaultMessage = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  margin-top: 8px;
`;

const DefaultMessagePrefix = styled.span``;
const DefaultMessageSuffix = styled.span``;
const AddMoreChartsHelperMessage = styled.div``;
const RecapLink = styled.span`
  cursor: pointer;
  color: #0074fc;
  text-decoration: underline;
`;

const AddedChartsContainer = styled.div`
  display: flex;
  margin-top: 27px;
  flex-direction: column;
  margin-bottom: 11px;
`;

const CheckboxItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction : column
  margin-bottom: 16px;

`;

const ChartCheckbox = styled(Checkbox)``;

const AddedChartsTabComponent = props => {
  const history = useHistory();

  const { dashboardChartIds, handleSuggestedChartsTabCheckBoxChange } = props;

  const addedChartIds = dashboardChartIds.filter(id => {
    const chartParams = parseParams(id);
    const isDefaultChart = chartParams[chartKeyParams.IS_DEFAULT_CHART] === "true";
    if (isDefaultChart) {
      return false;
    }
    const reportCurrentValue = recapReportValueSelector(store.getState(), chartParams.report_id);
    if (
      chartParams.chart_content === chartContent.INVESTABLE_ASSETS_GROUPED_BY_SECTION ||
      chartParams.chart_content === chartContent.INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SECTION
    ) {
      const node = recapReportNodeSelector(
        store.getState(),
        chartParams.report_id,
        true,
        chartTimeRange.TODAY,
        false,
        chartParams.chart_content === chartContent.INVESTABLE_ASSETS_GROUPED_BY_SECTION,
        false,
        chartParams.chart_content === chartContent.INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SECTION
      );
      const isAllSheetsHaveOneSection = node && node.sheets.every(sheet => sheet.sections.length === 1);
      return !isAllSheetsHaveOneSection && reportCurrentValue;
    }
    return reportCurrentValue;
  });
  const lineCharts = [];
  const donutCharts = [];
  const showDefaultMessage = addedChartIds.length === 0;

  for (const chartId of addedChartIds) {
    const chartStyleFromId = getChartStyleFromChartId(chartId);
    if (chartStyleFromId === chartStyle.LINE) {
      lineCharts.push(chartId);
    } else {
      donutCharts.push(chartId);
    }
  }

  const recapLinkClick = () => {
    history.push(`${process.env.PUBLIC_URL}${routes.REPORTS}`);
  };

  const renderLineCharts = () => {
    if (lineCharts.length) {
      return (
        <React.Fragment>
          <DefaultTitle>{"Line Charts"}</DefaultTitle>
          <AddedChartsContainer>
            {lineCharts.map((id, index) => {
              const chartParams = parseParams(id);
              const isChecked = chartParams.is_checked === "true";
              const reportId = chartParams.report_id;
              const reportName = recapReportNameSelector(store.getState(), reportId);
              const reportParams = parseParams(decodeURIComponent(reportId));
              const chartLabel = getLineChartTitle(
                reportParams.chart_option,
                reportParams.report_path,
                reportName,
                reportId
              );
              return (
                <CheckboxItemContainer key={index}>
                  <ChartCheckbox
                    label={chartLabel}
                    checked={isChecked}
                    onChange={checkedFlag => handleSuggestedChartsTabCheckBoxChange(checkedFlag, id)}
                  />
                </CheckboxItemContainer>
              );
            })}
          </AddedChartsContainer>
        </React.Fragment>
      );
    }
  };

  const renderDonutCharts = () => {
    if (donutCharts.length) {
      return (
        donutCharts.length && (
          <React.Fragment>
            <DefaultTitle>{"Allocation Charts"}</DefaultTitle>
            <AddedChartsContainer>
              {donutCharts.map((id, index) => {
                const chartParams = parseParams(id);
                const isChecked = chartParams.is_checked === "true";
                const chartLabel = getChartLabelFromId(id, chartParams.chart_option === recapChartOptions.NETWORTH.id);
                return (
                  <CheckboxItemContainer key={index}>
                    <ChartCheckbox
                      label={chartLabel}
                      checked={isChecked}
                      onChange={checkedFlag => handleSuggestedChartsTabCheckBoxChange(checkedFlag, id)}
                    />
                  </CheckboxItemContainer>
                );
              })}
            </AddedChartsContainer>
          </React.Fragment>
        )
      );
    }
  };

  if (showDefaultMessage) {
    return (
      <Container>
        <DefaultTitle>{i18n.t("chartsSettings.addedChartsTab.defaultTitle")}</DefaultTitle>
        <DefaultMessage>
          <DefaultMessagePrefix>{i18n.t("chartSettings.addedChartsTab.defaultMessagePrefix")}</DefaultMessagePrefix>
          <RecapLink onClick={recapLinkClick}>{"Recap"}</RecapLink>
          <DefaultMessageSuffix>{i18n.t("chartSettings.addedChartsTab.defaultMessageSuffix")}</DefaultMessageSuffix>
          <AddMoreChartsHelperMessage
            dangerouslySetInnerHTML={{
              __html: i18n.t("chartSettings.addedChartsTab.addMoreChartsMessage")
            }}
          />
        </DefaultMessage>
      </Container>
    );
  } else {
    return (
      <Container>
        {renderLineCharts()}
        {renderDonutCharts()}
      </Container>
    );
  }
};
export default AddedChartsTabComponent;
