import React from "react";
import styled from "styled-components";
import CurrencyHeaderLabel from "components/labels/CurrencyHeaderLabel";
import optionsIcon from "assets/images/options.svg";
import optionsIconDark from "assets/images/options_dark.svg";
import ContextMenu from "components/contextmenu/ContextMenu";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import { formatNumberWithKuberaNumberFormatSettings, shortFormatNumberWithCurrency } from "@kubera/common";
import BadgeTip from "components/tooltip/BadgeTip";
import requestIdleCallback from "utilities/requestIdleCallback";
import cancelIdleCallback from "utilities/cancelIdleCallback";
import * as d3 from "d3";
import { useDarkModeActive } from "theme";

export const stackedDoughnutChartColors = d3.schemeTableau10;

const minBarHeightToShowText = 48;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background: ${props => (props.hasBackgroundColor ? props.theme.netWorthContainerBG : "none")};
  border: ${props => (props.hasBorder ? props.theme.netWorthContainerBR : "none")};
  box-sizing: border-box;
  padding: 20px;
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
`;

const TitleContainer = styled.div`
display: flex
flex-direction : column`;

const ActionButton = styled.button`
  width: 30px;
  height: 30px;
  min-width: 30px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px 14px;

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:focus {
    background-color: ${props => props.theme.focusBackgroundColor};
  }
`;

const OptionButton = styled(ActionButton)`
  background-image: url(${props => (props.theme.mode === "default" ? optionsIcon : optionsIconDark)});
  margin-right: -12px;
`;

const Title = styled.div`
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
`;

const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 17px;
`;

const ValueLabel = styled(CurrencyHeaderLabel)`
  display: flex;
`;

const ValueSubText = styled.div`
  margin-left: 4px;
  padding-top: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
`;

const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: ${props => (!props.padding === false ? props.padding : "")};
  transform: translate3d(0, 0, 0);
  contain: size;

  & > svg {
    overflow: visible;
  }
`;

const RecommendationBadge = styled(BadgeTip)`
  display: flex
  align-items: center
  justify-content: center;
  width: 20px;
  height: 20px;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  margin-right : 18px
`;

const OptionsContainer = styled.div`
  display: flex;
`;

class StackedDoughnutChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.contextMenuRef = React.createRef();
    this.idleCallbackRef = React.createRef();
  }
  componentDidMount() {
    requestIdleCallback(() => {
      this.drawChart();
    });
  }

  componentDidUpdate(oldProps) {
    if (
      oldProps.chartData !== this.props.chartData ||
      oldProps.useMaskedValues !== this.props.useMaskedValues ||
      oldProps.isDarkModeActive !== this.props.isDarkModeActive
    ) {
      cancelIdleCallback(this.idleCallbackRef);
      this.idleCallbackRef = requestIdleCallback(() => {
        this.drawChart();
      });
    }
  }

  drawChart() {
    const chartData = this.getChartData();
    const chartWidth = this.props.containerWidth || this.props.chartWidth;
    const chartHeight = this.getChartHeight();
    const container = document.getElementById(this.props.id);
    if (!container) {
      return;
    }
    if (!d3) {
      return;
    }

    const previous = container.querySelector("svg");
    if (previous) {
      previous.remove();
    }

    var data = [{ group: "chart" }];
    for (let i = 0; i < chartData.length; i++) {
      let temp = data[0];
      temp[chartData[i].text] = chartData[i].value;
    }

    // Set up dimensions
    const margin = { top: 0, right: 0, bottom: 0, left: 0 };
    const width = chartWidth - margin.left - margin.right;
    const height = chartHeight - margin.top - margin.bottom;

    // Create SVG container
    var svg = d3
      .select(`#${this.props.id}`)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("style", "font-size: 12px; font-family: Inter; font-weight: 400;");

    // Create scales
    const xScale = d3
      .scaleBand()
      .rangeRound([0, width])
      .paddingInner(0.09)
      .align(0.5);
    const yScale = d3.scaleLinear().rangeRound([height, 0]);

    var stack = d3
      .stack()
      .keys(chartData.map(item => item.text))
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone);

    var series = stack(data);
    for (let i = 0; i < series.length; i++) {
      let item = series[i];
      item[0].data = { ...item[0].data };
      item[0].data.text = item.key;
      item[0].data.subText = chartData[i].subText;
    }

    xScale.domain(
      data.map(function(d) {
        return d.group;
      })
    );
    yScale.domain([
      0,
      d3.max(series, function(d) {
        return d3.max(d, function(d) {
          return d[1];
        });
      })
    ]);

    var color = d3
      .scaleOrdinal()
      .domain(chartData.map(item => item.text))
      .range(stackedDoughnutChartColors)
      .unknown("#ccc");

    // Create bars
    const barHeight = d => {
      return yScale(d[0]) - yScale(d[1]);
    };
    var bar = svg
      .selectAll("g")
      .data(series)
      .enter()
      .append("g")
      .attr("fill", function(d) {
        return color(d.key);
      })
      .selectAll("rect")
      .data(function(d) {
        return d;
      })
      .enter();

    bar
      .append("rect")
      .attr("opacity", 0.5)
      .attr("x", function(d) {
        return xScale(d.data.group);
      })
      .attr("y", function(d) {
        return yScale(d[1]);
      })
      .attr("height", function(d) {
        return barHeight(d);
      })
      .attr("width", xScale.bandwidth());

    bar
      .append("rect")
      .attr("opacity", 1)
      .attr("x", function(d) {
        return xScale(d.data.group);
      })
      .attr("y", function(d) {
        return yScale(d[1]);
      })
      .attr("height", function(d) {
        return barHeight(d);
      })
      .attr("width", 12);

    bar
      .append("rect")
      .attr("opacity", 1)
      .attr("fill", this.props.isDarkModeActive ? "#1A1A1A" : "#ffffff")
      .attr("x", function(d) {
        return xScale(d.data.group);
      })
      .attr("y", function(d) {
        return yScale(d[1]);
      })
      .attr("height", function(d) {
        return 1;
      })
      .attr("width", xScale.bandwidth());

    function wrap(d) {
      const padding = xScale(d.data.group) + 14;
      var self = d3.select(this),
        textLength = self.node().getComputedTextLength(),
        text = self.text();
      while (textLength > width - 2 * padding && text.length > 0) {
        text = text.slice(0, -1);
        self.text(text + "...");
        textLength = self.node().getComputedTextLength();
      }
    }

    bar
      .append("text")
      .attr("text-anchor", "start")
      .text(d => {
        return d.data.text;
      })
      .each(wrap)
      .attr("paint-order", "stroke")
      .attr("fill", "rgba(0,0,0)")
      .attr("x", function(d) {
        return xScale(d.data.group) + 18;
      })
      .attr("y", function(d) {
        return yScale(d[0]) - 25;
      });

    bar
      .append("text")
      .attr("text-anchor", "start")
      .text(d => {
        return d.data.subText;
      })
      .attr("paint-order", "stroke")
      .attr("fill", "rgba(0,0,0)")
      .attr("x", function(d) {
        return xScale(d.data.group) + 18;
      })
      .attr("y", function(d) {
        return yScale(d[0]) - 10;
      });
  }

  getChartData() {
    const chartHeight = this.getChartHeight();
    var chartData = this.props.chartData;

    chartData = chartData.labels.map((label, index) => {
      const subText = `${formatNumberWithKuberaNumberFormatSettings(
        chartData.percentages[index]
      )}% • ${shortFormatNumberWithCurrency(chartData.data[index], chartData.currency, true, true)}`;

      return {
        text: label,
        subText: subText,
        value: (chartHeight * chartData.percentages[index]) / 100,
        index: index,
        percent: chartData.percentages[index]
      };
    });

    const barsWithHeightLowerThanMin = chartData.filter(item => item.value < minBarHeightToShowText);
    if (barsWithHeightLowerThanMin.length > 0) {
      var remainingHeight = chartHeight;
      for (let i = chartData.length - 1; i >= 0; i--) {
        if (chartData[i].value < minBarHeightToShowText) {
          remainingHeight -= minBarHeightToShowText;
          chartData[i].value = minBarHeightToShowText;
        } else {
          const remainingBarsTotal = chartData.slice(0, i + 1).reduce((acc, item) => acc + item.value, 0);
          const heightRatio = chartData[i].value / remainingBarsTotal;
          const height = Math.max(minBarHeightToShowText, heightRatio * remainingHeight);
          remainingHeight -= height;
          chartData[i].value = height;
        }
      }
    }
    return chartData;
  }

  handleContextMenuClick(event) {
    if (this.contextMenuRef.current.isVisible() === true) {
      this.contextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();

    var menuItems = this.props.hideRearrangeOption
      ? [[contextMenuItemType.REMOVE]]
      : [[contextMenuItemType.REARRANGE, contextMenuItemType.REMOVE]];

    this.contextMenuRef.current.show(
      menuItems,
      targetPosition.left + targetPosition.width,
      targetPosition.top + targetPosition.height + 2,
      false,
      event.target
    );
  }

  getChartHeight() {
    // Allocate little more than the min bar height needed to show text
    // per slice so that the slices that have higher percentage show
    // up bigger than the ones with min bar height to show text to
    // create visual sense of proportion between slices
    const heightAllocationPerSlice = minBarHeightToShowText * 1.5;
    return Math.max(heightAllocationPerSlice * 2, this.props.chartData.labels.length * heightAllocationPerSlice);
  }

  render() {
    const {
      className,
      chartData,
      chartWidth,
      hasBorder = true,
      shouldShowValue = true,
      hasBackgroundColor = true,
      shouldShowContextMenu = false,
      handleRemoveChartSelection,
      hideTitle,
      handleChartClick,
      chartPadding,
      handleRearrangeChartSelection
    } = this.props;
    const isRecommendationPresent = chartData.recommendationCount > 0;
    const isComparisonChart = chartData.content && chartData.content === "reports";
    const chartHeight = this.getChartHeight();

    const renderChart = () => {
      return (
        <Container
          className={className}
          width={chartWidth}
          height={chartHeight + 100}
          hasBorder={hasBorder}
          hasBackgroundColor={hasBackgroundColor}
          onClick={e => {
            handleChartClick && handleChartClick(chartData.id);
          }}
        >
          <HeaderContainer>
            <TitleContainer>
              <Title>{!hideTitle === false ? "" : chartData.title}</Title>
              {shouldShowValue && (
                <ValueContainer>
                  <ValueLabel
                    value={isComparisonChart ? chartData.value : chartData.total}
                    currency={chartData.currency}
                    currencyFontSize={13}
                    valueFontSize={24}
                    height={"29px"}
                  />
                  {!chartData.totalSubText === false && <ValueSubText>{chartData.totalSubText}</ValueSubText>}
                </ValueContainer>
              )}
            </TitleContainer>
            <OptionsContainer>
              {isRecommendationPresent && <RecommendationBadge>{chartData.recommendationCount}</RecommendationBadge>}
              {shouldShowContextMenu && (
                <OptionButton
                  onClick={event => {
                    event.stopPropagation();
                    this.handleContextMenuClick(event);
                  }}
                />
              )}
            </OptionsContainer>
          </HeaderContainer>

          <ChartContainer id={this.props.id} padding={chartPadding} />
          <ContextMenu
            ref={this.contextMenuRef}
            onSelection={selectedItem => {
              if (selectedItem.id === contextMenuItemType.REMOVE.id) {
                handleRemoveChartSelection(chartData.id);
              } else if (selectedItem.id === contextMenuItemType.REARRANGE.id) {
                handleRearrangeChartSelection();
              }
            }}
          />
        </Container>
      );
    };
    return renderChart();
  }
}

const StackedDoughnutChartComponentWrapper = props => {
  const isDarkModeActive = useDarkModeActive();

  return <StackedDoughnutChartComponent {...props} isDarkModeActive={isDarkModeActive} />;
};

export default StackedDoughnutChartComponentWrapper;
