import { currentPortfolioSelector } from "./PortfolioReducer";
import { userAgeAtDate, userDobSelector, dateForUserAge } from "./AuthReducer";
import { planningVariables, planningAssetTypes, defaultTicker, repeatFrequency } from "../actions/PlanningActions";
import { getUuid, getKuberaDateString } from "../../utilities/Number";
import { getState } from "../../utilities";

export const MAX_SCENARIO_COUNT = 3;

export const planningOptionsStringSelector = (state, portfolio) => {
  if (!portfolio) {
    portfolio = currentPortfolioSelector(state);
  }

  const planningData = planningDataSelector(state, portfolio);
  if (planningData) {
    return JSON.stringify(planningData);
  }
};

export const planningOptionsSelector = (state, portfolio) => {
  if (!portfolio) {
    portfolio = currentPortfolioSelector(state);
  }

  const planningData = planningDataSelector(state, portfolio);
  if (planningData) {
    return planningData;
  }
  return null;
};

export const planningTargetDateSelector = (state, portfolio) => {
  if (!portfolio) {
    portfolio = currentPortfolioSelector(state);
  }

  var date = null;
  if (portfolio && portfolio.tsPlanningTargetDate) {
    // convert seconds to milliseconds
    date = new Date(portfolio.tsPlanningTargetDate * 1000);
    date.setHours(0, 0, 0);
  } else {
    //  By default return a date 10 years into the future
    date = new Date(new Date().setFullYear(new Date().getFullYear() + 10));
    date.setHours(0, 0, 0);
  }
  return date;
};

export const planningDataSelector = (state, portfolio) => {
  if (!portfolio) {
    portfolio = currentPortfolioSelector(state);
  }
  return portfolio.planning;
};

export const planningScenariosSelector = (state, portfolio) => {
  if (!portfolio) {
    portfolio = currentPortfolioSelector(state);
  }
  const planningData = planningDataSelector(state, portfolio);
  if (planningData && planningData.scenario && planningData.scenario.length > 0) {
    planningData.scenario = planningData.scenario.map(scenario => {
      const rulesForScenario = planningData.rule
        .filter(rule => {
          return rule && rule.scenarioId === scenario.id;
        })
        .sort((a, b) => (!a.sortKey === true ? 0 : a.sortKey.localeCompare(b.sortKey)));
      scenario.rule = rulesForScenario;
      return scenario;
    });
    planningData.scenario = overRideSavedScenerioRules(planningData.scenario);
    return planningData.scenario.slice(0, MAX_SCENARIO_COUNT);
  }
  return [getDefaultScenario.bind(state)(0, `${portfolio.id}-default`)];
};

const overRideSavedScenerioRules = scenarios => {
  const overridedScenerios = scenarios.map(scenario => {
    scenario.rule =
      scenario.rule &&
      scenario.rule.map(rule => {
        // override repeat frequency for rule 25 if a user has already created a scenario
        if (rule.type === "rule_25" && rule.data.months) {
          delete rule.data.months;
          rule.data[planningVariables.REPEAT] = {
            props: { disableRevisions: true },
            frequency: repeatFrequency.MONTHLY
          };
        }
        return rule;
      });
    return scenario;
  });
  return overridedScenerios;
};

export function getDefaultScenario(index = 0, id = getUuid()) {
  const state = getState(this);
  const getLetter = letterIndex => {
    const alpha = Array.from(Array(26)).map((e, i) => i + 65);
    return alpha.map(x => String.fromCharCode(x))[letterIndex];
  };
  var name = `Scenario ${getLetter(index)}`;

  var scenarios = null;
  const planningOptions = planningOptionsSelector(state);
  if (planningOptions && planningOptions.scenarios) {
    scenarios = planningOptions.scenarios;
  }

  if (!scenarios === false) {
    while (scenarios.filter(x => x.name === name).length > 0) {
      index++;
      name = `Scenario ${getLetter(index)}`;
    }
  }

  return {
    name: name,
    id: id,
    sortKey: 1,
    rule: [
      {
        id: getUuid(),
        type: "rule_2",
        data: {
          [planningVariables.ASSET_TYPE]: { value: planningAssetTypes.cash.key },
          [planningVariables.GROWTH_RATE]: { value: 2 }
        },
        sortKey: "0"
      },
      {
        id: getUuid(),
        type: "rule_2",
        data: {
          [planningVariables.ASSET_TYPE]: { value: planningAssetTypes.investable.key },
          [planningVariables.GROWTH_RATE]: { value: 7 }
        },
        sortKey: "1"
      },
      {
        id: getUuid(),
        type: "rule_17",
        data: {
          [planningVariables.AMOUNT_WITH_TAX]: {
            value: 10000,
            tickerId: defaultTicker().id,
            [planningVariables.TAX]: null
          },
          [planningVariables.NEW_INCOME]: { value: "Salary" },
          [planningVariables.REPEAT]: {
            frequency: repeatFrequency.MONTHLY,
            changeBy: {
              increasing: true,
              percentage: 10,
              frequency: repeatFrequency.YEARLY,
              dateOfYear: "1 Jan 2023"
            },
            till:
              !userDobSelector(state) === true
                ? null
                : { age: 50, date: getKuberaDateString(dateForUserAge(state, 50)) }
          }
        },
        sortKey: "2"
      },
      {
        id: getUuid(),
        type: "rule_21",
        data: {
          [planningVariables.AMOUNT]: { value: 6000, tickerId: defaultTicker().id },
          [planningVariables.NEW_EXPENSE]: { value: "Expenses" },
          [planningVariables.REPEAT]: { frequency: repeatFrequency.MONTHLY }
        },
        sortKey: "3"
      },
      {
        id: getUuid(),
        type: "rule_26",
        data: {
          [planningVariables.RATE_PER_YEAR]: { value: 3 }
        },
        sortKey: "4"
      }
    ]
  };
}

export const planningNetworthChartScenariosSelector = state => {
  const portfolio = currentPortfolioSelector(state);
  if (!portfolio === false && !portfolio.networthChartScenario === false) {
    const netWorthScenarios = portfolio.networthChartScenario;
    return netWorthScenarios;
  }
  const scenarios = planningScenariosSelector(state);
  return scenarios.map(item => item.id);
};
