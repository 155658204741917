import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { getSortKeyBetween, updatePlanningRuleBulk, withRouter } from "@kubera/common";
import { hashParams } from "routes";
import { useTheme } from "theme";
import { connect } from "react-redux";
import {
  planningVariables,
  getHashParams,
  currentPortfolioSelector,
  getRuleVariableDisplayValue,
  getRuleObject,
  convertCurrency,
  getTickerUsingId,
  getCurrentValueForCustodianItems,
  getUuid,
  duplicateRulesInstanceIds,
  isAppInViewMode,
  createPlanningRule,
  updatePlanningRule,
  deletePlanningRule,
  deleteRuleFromScenario,
  getTickerUsingShortName
} from "@kubera/common";
import { category } from "components/dashboard/DashboardComponent";
import { DialogOverlay } from "components/dialog/DialogOverlay";
import AddRuleDialog from "./AddRuleDialog";
import PercentageInputDialog from "./variable_dialogs/PercentageInputDialog";
import RaterPerYearDialog from "./variable_dialogs/RatePerYearDialog";
import AssetTypeDialog from "./variable_dialogs/AssetTypeDialog";
import ExpectedAmountDialog from "./variable_dialogs/ExpectedAmountDialog";
import DateAgeDialog from "./variable_dialogs/DateAgeDialog";
import CustodiansPickerDialog from "./variable_dialogs/CustodiansPickerDialog";
import TickersPickerDialog from "./variable_dialogs/TickersPickerDialog";
import NumberInputDialog from "./variable_dialogs/NumberInputDialog";
import RepeatsDialog from "./variable_dialogs/RepeatsDialog";
import TextInputDialog from "./variable_dialogs/TextInputDialog";
import MultiDateInputDialog from "./variable_dialogs/MultiDateInputDialog";
import optionsIcon from "assets/images/options.svg";
import optionsIconDark from "assets/images/options_dark.svg";
import ContextMenu, { contextMenuItemType } from "components/contextmenu/ContextMenu";
import dragGrabBarIcon from "assets/images/drag_drop_grabbar.svg";
import dragGrabBarIconDark from "assets/images/drag_drop_grabbar_dark.svg";
import { Draggable, Droppable, DragDropContext } from "@hello-pangea/dnd";
import ToggleSwitch from "components/inputs/ToggleSwitch";
import { ReactComponent as RuleErrorIcon } from "assets/images/link_account_error.svg";
import { arrangeSortKeysForIds } from "components/grid/GridDataModel";
import VestingScheduleDialog from "./variable_dialogs/VestingScheduleDialog";

const Container = styled.div`
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
`;

const RulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: ${props => `1px solid ${props.theme.gridSectionBorderColor}`};
  background: ${props => props.theme.gridRowUpdatedBackgroundColor};
`;

const RulesHeader = styled.div`
  padding: 27px 40px 8px 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.lightgray};
`;

const RuleList = styled.div`
  display: flex;
  flex-direction: column;
`;

const RuleItem = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0px;
  padding-right: 15px;
  border-bottom: ${props => (props.isDragging ? "" : `1px solid ${props.theme.gridSectionBorderColor}`)};
  border: ${props => (props.isDragging ? `1px solid ${props.theme.gridSectionBorderColor}` : "")};
  contain: layout, paint;

  &:hover {
    cursor: auto;
  }
`;

const DragGrabBar = styled.div`
  width: 40px;
  height: 40px;
  background-color: transparent;
  background-image: url(${props => (props.theme.mode === "default" ? dragGrabBarIcon : dragGrabBarIconDark)});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 6px 10px;
  visibility: hidden;

  ${RuleItem}:hover & {
    visibility: visible;
  }
`;

const RuleText = styled.div`
  flex: 1;
  line-height: 21px;
  padding-top: 11px;
  padding-bottom: 11px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-feature-settings: "ss01" on, "calt" off;
  opacity: ${props => (props.isDisabled === true ? "0.4" : "1")};
`;

const RuleSwitch = styled(ToggleSwitch)`
  margin-left: 10px;
`;

const RuleOptionsButton = styled.button`
  text-align: center;
  min-width: 36px;
  height: 36px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  margin-left: 10px;
  cursor: pointer;
  background-color: transparent;
  background-image: url(${props => (props.theme.mode === "default" ? optionsIcon : optionsIconDark)});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 13px 13px;
  visibility: ${props => (props.isHidden ? "hidden" : "visible")};
  margin-right: ${props => (props.isHidden ? "-25px" : "")};

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:disabled {
    opacity: 0.5;
    background-color: transparent;
  }
`;

const RuleError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin-left: 20px;
  margin-rigth: 10px;
  cursor: pointer;
  background-color: transparent;
`;

const RulesFooter = styled.div`
  padding: 20px 40px 20px 40px;
  display: ${props => (props.isHidden === true ? "none" : "flex")};
`;

const AddRuleButton = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on, "calt" off;
  color: ${props => props.theme.sidebarDescCLR};
  cursor: pointer;
`;

class ScenarioRulesComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showAddRuleDialog: false };

    this.handleAddRuleButtonClick = this.handleAddRuleButtonClick.bind(this);
    this.handleDimissAddRuleDialog = this.handleDimissAddRuleDialog.bind(this);
    this.handleDismissVariableDialog = this.handleDismissVariableDialog.bind(this);
    this.handleVariableUpdate = this.handleVariableUpdate.bind(this);
    this.handleAddScenarioRule = this.handleAddScenarioRule.bind(this);
    this.handleRuleOptionButtonClick = this.handleRuleOptionButtonClick.bind(this);
    this.handleContextMenuSelection = this.handleContextMenuSelection.bind(this);
    this.handleRuleDragEnd = this.handleRuleDragEnd.bind(this);
    this.handleRuleSwitchChange = this.handleRuleSwitchChange.bind(this);
    this.handleRuleErrorOnMouseEnter = this.handleRuleErrorOnMouseEnter.bind(this);

    this.contextMenuRef = React.createRef();
    this.contextMenuForRule = null;
  }

  isReadOnly() {
    return isAppInViewMode() === true || this.props.portfolio.write === 0;
  }

  handleAddRuleButtonClick(e) {
    this.setState({ showAddRuleDialog: true });
  }

  handleDimissAddRuleDialog() {
    this.setState({ showAddRuleDialog: false });
  }

  handleDismissVariableDialog() {
    DialogOverlay.dismiss(this.props.history, this.props.location);
  }

  handleVariableUpdate(ruleIndex, ruleVariable, value) {
    const rule = this.props.scenario.rule[ruleIndex];
    rule.data[ruleVariable] = value;
    this.props.updatePlanningRule(
      this.props.scenario.id,
      rule.id,
      {
        data: { ...rule.data, [ruleVariable]: value }
      },
      true
    );
  }

  handleAddScenarioRule(scenarioRule) {
    const copy = { ...scenarioRule, data: window.kbStructuredClone(scenarioRule.data) };
    const sortKeyForRuleBefore = this.props.scenario.rule[this.props.scenario.rule.length - 1].sortKey || null;
    const sortKeyForRuleAfter = null;
    const sortKeyForNewRule = getSortKeyBetween(sortKeyForRuleBefore, sortKeyForRuleAfter);
    copy.sortKey = sortKeyForNewRule;
    this.props.scenario.rule.push(copy);
    this.props.createPlanningRule(copy);

    requestAnimationFrame(() => {
      document.flashElement(copy.id);
    });
  }

  handleRuleErrorOnMouseEnter(event, rule) {
    const targetPosition = event.target.getBoundingClientRect();
    const hasMultipleScenarios = this.props.scenarios.length > 1;
    var menuItems = [];

    if (hasMultipleScenarios === true) {
      const moveMenuItems = [contextMenuItemType.CONFLICTING_RULE_TITLE, contextMenuItemType.MOVE_RULE_TITLE];
      const otherScenarios = this.props.scenarios.filter(item => item.id !== this.props.scenario.id);
      for (const scenario of otherScenarios) {
        var item = { ...contextMenuItemType.MOVE_RULE, id: `${contextMenuItemType.MOVE_RULE.id}-${scenario.id}` };
        item.label = scenario.name;
        item.targetScenario = scenario;
        moveMenuItems.push(item);
      }
      menuItems.push(moveMenuItems);

      menuItems.push([contextMenuItemType.REMOVE]);
    } else {
      menuItems.push([contextMenuItemType.CONFLICTING_RULE_TITLE, contextMenuItemType.REMOVE]);
    }

    this.contextMenuRef.current.show(
      menuItems,
      targetPosition.right - 10,
      targetPosition.top + targetPosition.height - 5,
      false,
      event.target
    );
    this.contextMenuForRule = rule;
  }

  handleRuleOptionButtonClick(event, rule) {
    const isRightClick = event.type === "contextmenu";

    if (this.contextMenuRef.current.isVisible() === true) {
      this.contextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();
    var menuItems = [[contextMenuItemType.DUPLICATE_RULE]];

    if (this.props.scenarios.length > 1 && this.props.scenario.rule.length > 1) {
      const moveMenuItems = [contextMenuItemType.MOVE_RULE_TITLE];
      const otherScenarios = this.props.scenarios.filter(item => item.id !== this.props.scenario.id);
      for (const scenario of otherScenarios) {
        var item = { ...contextMenuItemType.MOVE_RULE, id: `${contextMenuItemType.MOVE_RULE.id}-${scenario.id}` };
        item.label = scenario.name;
        item.targetScenario = scenario;
        moveMenuItems.push(item);
      }
      menuItems.push(moveMenuItems);
    }

    if (this.props.scenario.rule.length > 1) {
      menuItems.push([contextMenuItemType.REMOVE]);
    }

    if (isRightClick === true) {
      this.contextMenuRef.current.show(menuItems, event.clientX, event.clientY, true, null);
    } else {
      this.contextMenuRef.current.show(menuItems, targetPosition.right, targetPosition.top, false, event.target);
    }
    this.contextMenuForRule = rule;

    if (isRightClick === true) {
      event.preventDefault();
    }
  }

  handleContextMenuSelection(item) {
    if (item.id === contextMenuItemType.REMOVE.id) {
      this.props.deleteRuleFromScenario(this.props.scenario, this.contextMenuForRule.id);
    } else if (item.id === contextMenuItemType.DUPLICATE_RULE.id) {
      let currentScenario = this.props.scenario;
      var duplicatedRule = JSON.parse(JSON.stringify(this.contextMenuForRule));
      duplicatedRule.id = getUuid();
      duplicatedRule.scenarioId = currentScenario.id;
      const ruleIndex = currentScenario.rule.findIndex(item => item.id === this.contextMenuForRule.id);
      const sortKeyForRuleBefore = currentScenario.rule[ruleIndex].sortKey || null;
      const sortKeyForRuleAfter =
        ruleIndex + 1 === currentScenario.rule.length ? null : currentScenario.rule[ruleIndex + 1].sortKey;
      const sortKeyForDuplicateRule = getSortKeyBetween(sortKeyForRuleBefore, sortKeyForRuleAfter);
      duplicatedRule.sortKey = sortKeyForDuplicateRule;
      currentScenario.rule.splice(ruleIndex + 1, 0, duplicatedRule);
      this.props.createPlanningRule(duplicatedRule);
    } else if (item.id === `${contextMenuItemType.MOVE_RULE.id}-${item.targetScenario.id}`) {
      const ruleIndex = this.props.scenario.rule.findIndex(item => item.id === this.contextMenuForRule.id);
      const rule = { ...this.props.scenario.rule[ruleIndex] };
      var targetScenario = item.targetScenario;
      rule.scenarioId = targetScenario.id;
      this.props.scenario.rule.splice(ruleIndex, 1);
      this.props.updatePlanningRule(targetScenario.id, rule.id, { scenarioId: targetScenario.id });
    }

    this.contextMenuForRule = null;
  }

  handleRuleDragEnd(result) {
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    let updatedScenario = this.props.scenario;

    const itemToMove = updatedScenario.rule.splice(sourceIndex, 1);
    updatedScenario.rule.splice(destinationIndex, 0, itemToMove[0]);
    const rulesArranged = arrangeSortKeysForIds(updatedScenario.rule);
    this.props.updatePlanningRuleBulk(updatedScenario.id, rulesArranged.list);
  }

  handleRuleSwitchChange(checked, rule) {
    var updatedScenario = this.props.scenario;
    const scenarioRule = updatedScenario.rule.find(item => item.id === rule.id);
    if (!scenarioRule === false) {
      scenarioRule.disabled = !checked ? 1 : 0;
    }
    this.props.updatePlanningRule(updatedScenario.id, rule.id, { disabled: !checked ? 1 : 0 });
  }

  getRuleAnchoredText(index, ruleObject, isDisabled) {
    if (!ruleObject === true) {
      return null;
    }

    var label = ruleObject.label(ruleObject.data);
    for (const key in ruleObject.data) {
      const linkTemplate = `<a style="color:${this.props.theme.linkColor}; cursor:${
        this.isReadOnly() ? "default" : "pointer"
      }" href=#${hashParams.RULE_INDEX}=${index}&${hashParams.RULE_VARIABLE}=${key}>#variable_string#</a>`;
      var linkForKey = "";

      if (key === planningVariables.REPEAT || key === planningVariables.DATE_AGE_REVISED) {
        linkForKey = getRuleVariableDisplayValue(key, ruleObject, linkTemplate);
      } else {
        linkForKey = linkTemplate.replace("#variable_string#", getRuleVariableDisplayValue(key, ruleObject));
      }
      label = label.replace(`#${key}#`, linkForKey);
    }
    return label;
  }

  getCurrentValueForExpectedAmountRule(rule) {
    if (!rule.data[planningVariables.ASSET_ID] === false) {
      return getCurrentValueForCustodianItems(rule.data[planningVariables.ASSET_ID].items);
    }
    if (!rule.data[planningVariables.TICKER_ID] === false) {
      const tickerId = rule.data[planningVariables.TICKER_ID].items[0];
      return convertCurrency(1, getTickerUsingId(tickerId).shortName, this.props.portfolio.currency);
    }
    return null;
  }

  getVariableModal() {
    const urlHashParams = getHashParams(this.props.location);
    const ruleIndex = urlHashParams[hashParams.RULE_INDEX];
    const ruleVariable = urlHashParams[hashParams.RULE_VARIABLE];

    if (!ruleVariable === true || !ruleIndex === true) {
      return null;
    }

    const rule = this.props.scenario.rule[ruleIndex];
    const isCostWithTax = ruleVariable === planningVariables.COST_WITH_TAX;
    switch (ruleVariable) {
      case planningVariables.GROWTH_RATE:
        return (
          <RaterPerYearDialog
            title={i18n.t("ratePerYear")}
            data={rule.data[ruleVariable]}
            onVariableUpdate={value => this.handleVariableUpdate(ruleIndex, ruleVariable, value)}
            onDismiss={this.handleDismissVariableDialog}
          />
        );
      case planningVariables.RATE_PER_YEAR_WITH_TAX:
      case planningVariables.RATE_PER_YEAR:
        return (
          <PercentageInputDialog
            title={i18n.t("ratePerYear")}
            data={rule.data[ruleVariable]}
            onVariableUpdate={value => this.handleVariableUpdate(ruleIndex, ruleVariable, value)}
            onDismiss={this.handleDismissVariableDialog}
            deductionDescriptionNoun={rule.type === "rule_5" ? "assetAppreciation" : "yourIncome"}
            portfolioCurrency={this.props.portfolio.currency}
            portfolioTickerId={getTickerUsingShortName(this.props.portfolio.currency).id}
          />
        );
      case planningVariables.REVISED_PERCENTAGE:
      case planningVariables.PERCENTAGE:
        return (
          <PercentageInputDialog
            title={i18n.t("percentage")}
            data={rule.data[ruleVariable]}
            onVariableUpdate={value => this.handleVariableUpdate(ruleIndex, ruleVariable, value)}
            onDismiss={this.handleDismissVariableDialog}
          />
        );
      case planningVariables.ASSET_TYPE:
        return (
          <AssetTypeDialog
            data={rule.data[ruleVariable]}
            onVariableUpdate={value => this.handleVariableUpdate(ruleIndex, ruleVariable, value)}
            onDismiss={this.handleDismissVariableDialog}
          />
        );
      case planningVariables.COST_WITH_TAX:
      case planningVariables.AMOUNT_WITH_TAX:
      case planningVariables.AMOUNT:
      case planningVariables.EXPECTED_AMOUNT:
        return (
          <ExpectedAmountDialog
            title={isCostWithTax ? i18n.t("cost") : undefined}
            hasCurrentValue={ruleVariable === planningVariables.EXPECTED_AMOUNT}
            currentValue={
              ruleVariable === planningVariables.EXPECTED_AMOUNT
                ? this.getCurrentValueForExpectedAmountRule(rule)
                : null
            }
            currentValueTickerId={
              (rule.data[planningVariables.TICKER_ID]?.items && rule.data[planningVariables.TICKER_ID].items[0]) || null
            }
            isPrice={!rule.data[planningVariables.TICKER_ID] === false}
            portfolioCurrency={this.props.portfolio.currency}
            data={rule.data[ruleVariable]}
            onVariableUpdate={value => this.handleVariableUpdate(ruleIndex, ruleVariable, value)}
            onDismiss={this.handleDismissVariableDialog}
            onGainTitle={ruleVariable === planningVariables.COST_WITH_TAX}
            deductionDescriptionNoun={
              rule.type === "rule_10" || isCostWithTax
                ? "yourProfit"
                : rule.type === "rule_9"
                ? "theDistribution"
                : "yourIncome"
            }
          />
        );
      case planningVariables.DATE_AGE_REVISED:
      case planningVariables.DATE_AGE_YEAR:
      case planningVariables.DATE_AGE:
        return (
          <DateAgeDialog
            data={rule.data[ruleVariable]}
            onVariableUpdate={value => this.handleVariableUpdate(ruleIndex, ruleVariable, value)}
            onDismiss={this.handleDismissVariableDialog}
            enableRevisions={ruleVariable === planningVariables.DATE_AGE_REVISED}
          />
        );
      case planningVariables.DEBT_ID:
      case planningVariables.ASSET_ID:
        return (
          <CustodiansPickerDialog
            category={ruleVariable === planningVariables.DEBT_ID ? category.DEBT : category.ASSET}
            data={rule.data[ruleVariable]}
            onVariableUpdate={value => this.handleVariableUpdate(ruleIndex, ruleVariable, value)}
            onDismiss={this.handleDismissVariableDialog}
          />
        );
      case planningVariables.TICKER_ID:
        return (
          <TickersPickerDialog
            rule={rule}
            data={rule.data[ruleVariable]}
            onVariableUpdate={value => this.handleVariableUpdate(ruleIndex, ruleVariable, value)}
            onDismiss={this.handleDismissVariableDialog}
          />
        );
      case planningVariables.VESTING_SCHEDULE:
        return (
          <VestingScheduleDialog
            data={rule.data[ruleVariable]}
            onVariableUpdate={value => this.handleVariableUpdate(ruleIndex, ruleVariable, value)}
            onDismiss={this.handleDismissVariableDialog}
          />
        );
      case planningVariables.MONTHS:
        return (
          <NumberInputDialog
            title={i18n.t("months")}
            data={rule.data[ruleVariable]}
            onVariableUpdate={value => this.handleVariableUpdate(ruleIndex, ruleVariable, value)}
            onDismiss={this.handleDismissVariableDialog}
          />
        );
      case planningVariables.QUANTITY:
        return (
          <NumberInputDialog
            title={i18n.t("quantity")}
            data={rule.data[ruleVariable]}
            onVariableUpdate={value => this.handleVariableUpdate(ruleIndex, ruleVariable, value)}
            onDismiss={this.handleDismissVariableDialog}
          />
        );
      case planningVariables.REPEAT:
        return (
          <RepeatsDialog
            rule={rule}
            data={rule.data[ruleVariable]}
            onVariableUpdate={value => this.handleVariableUpdate(ruleIndex, ruleVariable, value)}
            onDismiss={this.handleDismissVariableDialog}
            showDateOfYearInput={rule.data[planningVariables.DATE_AGE] === undefined}
          />
        );
      case planningVariables.TICKER_NAME:
      case planningVariables.NEW_EXPENSE:
      case planningVariables.NEW_INCOME:
      case planningVariables.META:
      case planningVariables.NEW_DEBT:
      case planningVariables.NEW_ASSET:
        return (
          <TextInputDialog
            title={this.getTitleForTextInputDialog(ruleVariable)}
            data={rule.data[ruleVariable]}
            onVariableUpdate={value => this.handleVariableUpdate(ruleIndex, ruleVariable, value)}
            onDismiss={this.handleDismissVariableDialog}
          />
        );
      case planningVariables.MULTIPLE_DATES:
        return (
          <MultiDateInputDialog
            data={rule.data[ruleVariable]}
            onVariableUpdate={value => this.handleVariableUpdate(ruleIndex, ruleVariable, value)}
            onDismiss={this.handleDismissVariableDialog}
          />
        );
      default:
        return null;
    }
  }

  getTitleForTextInputDialog(ruleVariable) {
    switch (ruleVariable) {
      case planningVariables.NEW_ASSET:
        return i18n.t("newAsset");
      case planningVariables.NEW_DEBT:
        return i18n.t("newDebt");
      case planningVariables.NEW_EXPENSE:
        return i18n.t("newExpense");
      case planningVariables.NEW_INCOME:
        return i18n.t("newIncome");
      case planningVariables.META:
        return i18n.t("newMeta");
      case planningVariables.TICKER_NAME:
        return i18n.t("tickerName");
      default:
        return null;
    }
  }

  render() {
    const viewOnlyMode = this.isReadOnly();
    const duplicateRuleInstanceIds = duplicateRulesInstanceIds(this.props.scenario.rule);

    return (
      <Container>
        <ContentContainer>
          <RulesContainer>
            <RulesHeader>{i18n.t("rules")}</RulesHeader>
            <DragDropContext onDragEnd={this.handleRuleDragEnd}>
              <Droppable droppableId="rules_droppable">
                {(provided, snapshot) => (
                  <RuleList {...provided.droppableProps} ref={provided.innerRef}>
                    {this.props.scenario.rule.map((rule, index) => {
                      const ruleObject = getRuleObject(rule);

                      if (ruleObject.isHidden === true) {
                        return null;
                      }
                      const isDuplicate = duplicateRuleInstanceIds.includes(rule.id);
                      return (
                        <Draggable draggableId={rule.id} index={index} key={rule.id}>
                          {(provided, snapshot) => (
                            <RuleItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              isDragging={snapshot.isDragging}
                              id={rule.id}
                            >
                              <DragGrabBar {...provided.dragHandleProps} />
                              <RuleText
                                key={index}
                                dangerouslySetInnerHTML={{
                                  __html: this.getRuleAnchoredText(
                                    index,
                                    ruleObject,
                                    !rule.disabled === false || isDuplicate === true
                                  )
                                }}
                                isDisabled={!rule.disabled === false || isDuplicate === true}
                              />
                              {isDuplicate === true && (
                                <RuleError onMouseEnter={e => this.handleRuleErrorOnMouseEnter(e, rule)}>
                                  <RuleErrorIcon />
                                </RuleError>
                              )}
                              <RuleSwitch
                                disabled={isDuplicate === true || viewOnlyMode === true}
                                isChecked={isDuplicate ? false : !rule.disabled === true}
                                onChange={checked => this.handleRuleSwitchChange(checked, rule)}
                              />
                              <RuleOptionsButton
                                isHidden={viewOnlyMode === true}
                                onClick={e => this.handleRuleOptionButtonClick(e, rule)}
                              />
                            </RuleItem>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </RuleList>
                )}
              </Droppable>
            </DragDropContext>
            <RulesFooter isHidden={viewOnlyMode === true}>
              <AddRuleButton onClick={this.handleAddRuleButtonClick}>{i18n.t("addRule")}</AddRuleButton>
            </RulesFooter>
          </RulesContainer>
        </ContentContainer>

        {this.state.showAddRuleDialog === true && (
          <AddRuleDialog
            scenario={this.props.scenario}
            onAddScenarioRule={this.handleAddScenarioRule}
            onDismiss={this.handleDimissAddRuleDialog}
          />
        )}
        {!viewOnlyMode && this.getVariableModal()}
        <ContextMenu dataPrivate ref={this.contextMenuRef} width={223} onSelection={this.handleContextMenuSelection} />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  portfolio: currentPortfolioSelector(state)
});

const mapDispatchToProps = {
  deleteRuleFromScenario: deleteRuleFromScenario,
  createPlanningRule: createPlanningRule,
  updatePlanningRule: updatePlanningRule,
  deletePlanningRule: deletePlanningRule,
  updatePlanningRuleBulk: updatePlanningRuleBulk
};

const ScenarioRulesComponentWrapper = props => {
  const theme = useTheme();

  return <ScenarioRulesComponent {...props} theme={theme} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ScenarioRulesComponentWrapper));
