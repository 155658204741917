import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { planningVariables, withRouter } from "@kubera/common";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { getRuleText, getRuleObject, getRuleNodeKey, planningAssetTypes } from "@kubera/common";
import CurrencyHeaderLabel from "components/labels/CurrencyHeaderLabel";
import CurrencyLabel from "components/labels/CurrencyLabel";
import { ReactComponent as ExpandIcon } from "assets/images/expandable_indicator.svg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const BreakdownDialog = styled(Dialog)`
  position: relative;
  width: 720px;
  min-height: 790px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 50px 40px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const TimeRange = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.03em;
  font-feature-settings: "ss01" on;
  color: #000000;
  text-transform: uppercase;
`;

const CashflowTabs = styled(Tabs)`
  margin-top: 15px;
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const CashflowTabList = styled(TabList)`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;
`;

const CashflowTabHeaderContainer = styled.div`
  display: flex;
`;

const CashflowTabHeader = styled(Tab)`
  margin-right: 25px;
  border: 0;
  cursor: pointer;
  z-index: 100;
  opacity: 0.35;
  display: ${props => (props.isHidden === true ? "none" : "auto")};

  &.is-selected {
    opacity: 1;
  }
`;

const CashflowTabPanel = styled(TabPanel)`
  display: none;
  flex: 1;

  &.is-selected {
    display: flex;
  }
`;

const ValueItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 60px;
  padding-left: 8px;
  border-left: ${props => `6px solid ${props.color}`};
  cursor: pointer;
  margin-bottom: 10px;
`;

const ValueName = styled.div`
  margin-bottom: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  margin-top: -3px;
`;

const ValueLabel = styled(CurrencyHeaderLabel)`
  margin-top: -4px;
  color: black;
  height: 23px;
`;

const BreakdownTable = styled.div`
  width: 100%;
  margin-top: 30px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const BreakdownRow = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 22px 14px 28px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
  background: #f1f1f1;
  border-top: ${props => (props.hideBorder === true ? "" : "1px solid rgba(0, 0, 0, 0.1)")};
`;

const HeaderRow = styled(BreakdownRow)`
  cursor: pointer;
  background: transparent;
`;

const FooterRow = styled(BreakdownRow)`
  font-weight: 700;
  background: transparent;
`;

const TitleContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  flex: 1;
`;

const Description = styled.div`
  margin-top: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.4);
`;

const Value = styled(CurrencyLabel)`
  margin-left: 20px;
`;

const ExpandArrow = styled(ExpandIcon)`
  width: 10px;
  height: 10px;
  margin-right: 10px;
  margin-left: -20px;
  transform: ${props => (props.isCollapsed === 1 ? "rotate(-90deg)" : "")};
`;

const ValueContainer = styled.div`
  display: flex;
`;

class CashflowBreakdownDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsedKeys: [],
      selectedTabIndex: this.props.showOutflow ? 1 : 0
    };

    this.handleHeaderClick = this.handleHeaderClick.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
  }

  handleTabSelect(index) {
    this.setState({ selectedTabIndex: index });
  }

  handleHeaderClick(e, key) {
    var collapsedKeys = this.state.collapsedKeys;
    if (collapsedKeys.includes(key)) {
      collapsedKeys = collapsedKeys.filter(item => item !== key);
    } else {
      collapsedKeys.push(key);
    }
    this.setState({ collapsedKeys });
  }

  getRuleText(rule) {
    if (rule.id === planningVariables.TAX) return "Tax";
    const scenarioRule = this.props.scenarioRules.find(item => item.id === rule.id);
    var displayString = getRuleText(rule.type, scenarioRule.data, undefined, planningAssetTypes.cash.label);
    if (rule.isNonCashRule === true) {
      displayString += " (Cash)";
    }
    return displayString;
  }

  getRuleDescription(rule) {
    const ruleObject = getRuleObject(rule);
    if (ruleObject.getSubtext) return ruleObject.getSubtext(rule, planningAssetTypes.cash.label);

    if (!rule.overridingRules === false && rule.overridingRules.length > 0) {
      var overridenLabels = rule.overridingRules.map(overridingRule => {
        const ruleObject = getRuleObject(this.props.scenarioRules.find(item => item.id === overridingRule.id));
        return getRuleNodeKey(ruleObject);
      });
      return `${i18n.t("less")} ${overridenLabels.join(", ")}`;
    }
    return null;
  }

  getRulesBreakdown(isInflow) {
    const index = this.props.dataIndex === null ? this.props.breakdownData.length - 1 : this.props.dataIndex;
    return isInflow
      ? this.props.breakdownData[index].inflow.breakdown
      : this.props.breakdownData[index].outflow.breakdown;
  }

  getTableForBreakdown(breakdown, totalChange, isInflow) {
    const nonEmptyKeys = Object.keys(breakdown).filter(
      key =>
        !breakdown[key].rules === true ||
        breakdown[key].rules.filter(rule => rule.changes.cumulativeDelta !== 0).length > 0
    );

    return (
      <BreakdownTable>
        {nonEmptyKeys.map((key, index) => {
          const label = breakdown[key].label;
          const isKeyExpanded = this.state.collapsedKeys.includes(key) === false;

          const totalChange = breakdown[key].rules.reduce((total, rule) => {
            return total + rule.changes.cumulativeDelta;
          }, 0);

          return (
            <div key={index}>
              <HeaderRow
                hideBorder={index === 0}
                isExpandable={true}
                onClick={e => {
                  this.handleHeaderClick(e, key);
                }}
              >
                <ExpandArrow iscollapsed={isKeyExpanded === false ? 1 : 0} />
                <TitleContainer>
                  <Title>{label}</Title>
                </TitleContainer>
                <Value currency={this.props.currency} roundDown={true} value={totalChange} />
              </HeaderRow>
              {isKeyExpanded === true && (
                <>
                  {breakdown[key].rules.map((rule, index) => {
                    if (rule.changes.cumulativeDelta === 0) {
                      return null;
                    }

                    const description = this.getRuleDescription(rule);
                    return (
                      <BreakdownRow key={index}>
                        <TitleContainer>
                          <Title>{this.getRuleText(rule, breakdown)}</Title>
                          {!description === false && <Description>{description}</Description>}
                        </TitleContainer>
                        <Value currency={this.props.currency} roundDown={true} value={rule.changes.cumulativeDelta} />
                      </BreakdownRow>
                    );
                  })}
                </>
              )}
            </div>
          );
        })}
        <FooterRow hideBorder={false}>
          <TitleContainer>
            <Title>
              {isInflow === true ? `${i18n.t("cash")} ${i18n.t("inflow")}` : `${i18n.t("cash")} ${i18n.t("outflow")}`}
            </Title>
          </TitleContainer>
          <Value currency={this.props.currency} roundDown={true} value={totalChange} />
        </FooterRow>
      </BreakdownTable>
    );
  }

  getTabs() {
    const inflowTotal = this.props.getInflowTotal(this.props.breakdownData, this.props.dataIndex);
    const outflowTotal = this.props.getOutflowTotal(this.props.breakdownData, this.props.dataIndex);

    var tabs = [];
    const inflowHeader = (
      <ValueContainer>
        <ValueItem color={this.props.colorPallete.color} onClick={this.handleTotalClick}>
          <ValueName>{i18n.t("inflow")}</ValueName>
          <ValueLabel
            currency={this.props.currency}
            value={inflowTotal}
            currencyFontSize={14}
            valueFontSize={22}
            fontWeight={700}
            height={20}
            showZero={true}
            preventAnimation={true}
            currencyMarginTop={2}
          />
        </ValueItem>
      </ValueContainer>
    );
    const inflowPanel = this.getTableForBreakdown(this.getRulesBreakdown(true), inflowTotal, true);

    const outflowHeader = (
      <ValueContainer>
        <ValueItem color={this.props.colorPallete.barChartSecondaryColor} onClick={this.handleTotalClick}>
          <ValueName>{i18n.t("outflow")}</ValueName>
          <ValueLabel
            currency={this.props.currency}
            value={outflowTotal}
            currencyFontSize={14}
            valueFontSize={22}
            fontWeight={700}
            height={20}
            showZero={true}
            preventAnimation={true}
            currencyMarginTop={2}
          />
        </ValueItem>
      </ValueContainer>
    );
    const outflowPanel = this.getTableForBreakdown(this.getRulesBreakdown(false), outflowTotal, false);

    tabs.push({ key: "inflow", header: inflowHeader, panel: inflowPanel });
    tabs.push({ key: "outflow", header: outflowHeader, panel: outflowPanel });
    return tabs;
  }

  render() {
    const tabs = this.getTabs();

    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <BreakdownDialog>
          <Container>
            <TimeRange>{this.props.timeRange}</TimeRange>
            <CashflowTabs
              selectedTabClassName="is-selected"
              selectedTabPanelClassName="is-selected"
              selectedIndex={this.state.selectedTabIndex}
              onSelect={this.handleTabSelect}
            >
              <CashflowTabList>
                {tabs.map((tab, index) => (
                  <CashflowTabHeader data-cy={"breakdownTab" + index} key={index}>
                    <CashflowTabHeaderContainer>{tab.header}</CashflowTabHeaderContainer>
                  </CashflowTabHeader>
                ))}
              </CashflowTabList>
              {tabs.map((tab, index) => (
                <CashflowTabPanel key={`cashflowPanel${index}`}>{tab.panel}</CashflowTabPanel>
              ))}
            </CashflowTabs>
          </Container>
        </BreakdownDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CashflowBreakdownDialog));
