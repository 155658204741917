import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import {
  getLineChartGroupedDataForScenario,
  months,
  portfolioCashOnHand,
  portfolioNetWorth,
  parseKuberaDateString
} from "@kubera/common";
import CurrencyHeaderLabel from "components/labels/CurrencyHeaderLabel";
import ChangeLabel from "components/labels/ChangeLabel";
import LineChartComponent from "components/charts/LineChartComponent";
import NetworthCashBreakdownDialog from "../breakdown/NetworthCashBreakdownDialog";

const Container = styled.div`
  width: 100%;
  background: ${props => props.theme.gridRowUpdatedBackgroundColor};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
`;

const ValueContainer = styled.div`
  display: flex;
`;

const ValueItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-left: ${props => (props.showBorder === true ? "30px" : "20px")};
  padding-left: 6px;
  border-left: ${props => (props.showBorder === true ? `6px solid ${props.color}` : "")};
  cursor: pointer;
`;

const ValueName = styled.div`
  margin-bottom: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const ValueLabel = styled(CurrencyHeaderLabel)`
  margin-top: -4px;
`;

const ValueChangeLabel = styled(ChangeLabel)`
  width: fit-content;
  margin-top: -4px;
  margin-bottom: -4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const LineChart = styled(LineChartComponent)`
  cursor: pointer;
  margin-top: 25px;
`;

class NetworthCashChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLastDataPointIndex: null,
      preventAnimation: false,
      showBreakdown: false,
      breakdownIndex: null
    };

    this.handleValueItemClick = this.handleValueItemClick.bind(this);
    this.handleLineChartHoverOverSection = this.handleLineChartHoverOverSection.bind(this);
    this.handleLineChartClickOverSection = this.handleLineChartClickOverSection.bind(this);
    this.handleBreakdownDimiss = this.handleBreakdownDimiss.bind(this);
  }

  handleLineChartClickOverSection(index) {
    this.setState({ breakdownIndex: index, showBreakdown: true });
  }

  handleBreakdownDimiss() {
    this.setState({ breakdownIndex: null, showBreakdown: false });
  }

  handleLineChartHoverOverSection(index, data) {
    this.props.handleChartHover(index !== null ? data.data[index] : null);
    this.setState({ currentLastDataPointIndex: index, preventAnimation: true }, () => {
      if (this.state.currentLastDataPointIndex === null) {
        setTimeout(() => {
          this.setState({ preventAnimation: false });
        }, 1000);
      }
    });
  }

  handleValueItemClick(e, groupedData, showCash = false) {
    this.setState({
      showBreakdown: true,
      breakdownIndex: groupedData.data.length - 1,
      showCash
    });
  }

  getEndDataPoint(groupedData, dataIndex) {
    const dataPoints = groupedData.data;
    const dataPointIndex = dataIndex !== null ? dataIndex : dataPoints.length - 1;
    return dataPoints[dataPointIndex];
  }

  getColorPalette() {
    return this.props.colorPallete[this.props.selectedScenarioIndex];
  }

  getLineChartData(groupedData) {
    const data = {
      labels: groupedData.data.map((dataPoint, index) => {
        const date = parseKuberaDateString(dataPoint.date);
        return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`.toUpperCase();
      }),
      labelFormat: label => {
        const date = parseKuberaDateString(label);
        return `${months[date.getMonth()]} ${date.getFullYear()}`.toUpperCase();
      },
      datasets: [],
      options: [
        { borderColor: this.getColorPalette().color, borderWidth: 2 },
        { borderColor: this.getColorPalette().color, borderWidth: 2 }
      ]
    };

    const networthDataSet = {
      data: groupedData.data.map((dataPoint, index) => {
        return dataPoint.networth;
      })
    };
    const cashDataSet = {
      data: groupedData.data.map((dataPoint, index) => {
        return dataPoint.cashTotal;
      })
    };

    data.datasets.push(networthDataSet);
    data.datasets.push(cashDataSet);
    return data;
  }

  getDurationLabel(chartData, index) {
    if (index === null) {
      return chartData.labels[chartData.labels.length - 1];
    } else {
      return chartData.labels[index];
    }
  }

  render() {
    const colorPallete = this.getColorPalette();

    if (!this.props.planningData === true || !this.props.planningData[this.props.selectedScenarioIndex] === true) {
      return null;
    }

    const groupedData = getLineChartGroupedDataForScenario(this.props.planningData[this.props.selectedScenarioIndex]);
    const lineChartData = this.getLineChartData(groupedData);

    const endDataPoint = this.getEndDataPoint(groupedData, this.state.currentLastDataPointIndex);
    if (!endDataPoint === true) {
      return null;
    }

    return (
      <Container>
        <ContentContainer>
          <ValueContainer>
            <ValueItem
              color={colorPallete.color}
              showBorder={true}
              onClick={e => this.handleValueItemClick(e, groupedData, false)}
            >
              <ValueName>{i18n.t("networth")}</ValueName>
              <ValueLabel
                currency={groupedData.currency}
                value={endDataPoint.networth}
                currencyFontSize={16}
                valueFontSize={30}
                fontWeight={400}
                height={"36px"}
                showZero={true}
                preventAnimation={this.state.preventAnimation === true}
              />
              <ValueChangeLabel
                currency={groupedData.currency}
                startValue={this.props.portfolioNetWorth}
                endValue={endDataPoint.networth}
                preventAnimation={this.state.preventAnimation === true}
              />
            </ValueItem>
            <ValueItem color={colorPallete.color} onClick={e => this.handleValueItemClick(e, groupedData, true)}>
              <ValueName>{i18n.t("cash")}</ValueName>
              <ValueLabel
                currency={groupedData.currency}
                value={Math.round(endDataPoint.cashTotal)}
                currencyFontSize={16}
                valueFontSize={30}
                fontWeight={400}
                height={"36px"}
                showZero={true}
                preventAnimation={this.state.preventAnimation === true}
              />
              <ValueChangeLabel
                currency={groupedData.currency}
                startValue={this.props.portfolioCashOnHand}
                endValue={endDataPoint.cashTotal}
                preventAnimation={this.state.preventAnimation === true}
              />
            </ValueItem>
          </ValueContainer>
          <LineChart
            data={lineChartData}
            chartHeight={243}
            adjustPageScroll={true}
            chartPadding={{
              left: 0,
              right: 0,
              top: 30,
              bottom: 0
            }}
            chartBgColor={this.props.colorPallete[this.props.selectedScenarioIndex].lineChartBackgroundColor}
            onHoverOverSection={index => this.handleLineChartHoverOverSection(index, groupedData)}
            onClickOverSection={this.handleLineChartClickOverSection}
            scaleType={"time"}
          />
          {this.state.showBreakdown === true && (
            <NetworthCashBreakdownDialog
              colorPallete={colorPallete}
              groupedData={groupedData}
              dataForScenario={this.props.planningData[this.props.selectedScenarioIndex]}
              dataIndex={this.state.breakdownIndex}
              getEndDataPoint={this.getEndDataPoint}
              onDismiss={this.handleBreakdownDimiss}
              showCash={this.state.showCash}
            />
          )}
        </ContentContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  portfolioNetWorth: portfolioNetWorth(state),
  portfolioCashOnHand: portfolioCashOnHand(state)
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NetworthCashChartComponent));
