import { useMemo } from "react";
import i18n from "i18next";
import { useSelector } from "react-redux";

import {
  accountPlanSelector,
  accountSubscriptionStatusSelector,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_STATUS,
  userPriceOptionSelector,
  userDiscountSelector,
  getTickerUsingId,
  getExchangeRate,
  getOwnershipValueForCustodianReselectFn,
  calcCustodianOwnershipValue,
  convertCurrency
} from "@kubera/common";

import { filterDataFromPortfolioDetails } from "components/grid/GridDataModel";

export const useNextSubscriptionPlanTxt = ({ considerDiscount = false } = {}) => {
  const userDiscount = useSelector(userDiscountSelector);
  const accountPlanFromSelector = useSelector(accountPlanSelector);
  const accountPlan =
    accountPlanFromSelector !== SUBSCRIPTION_PLANS.TRIAL ? SUBSCRIPTION_PLANS.YEARLY : SUBSCRIPTION_PLANS.TRIAL;
  const accountSubscriptionStatus = useSelector(accountSubscriptionStatusSelector);
  const userPriceOption = useSelector(userPriceOptionSelector);

  const isSubscriptionActive =
    accountPlan !== SUBSCRIPTION_PLANS.TRIAL &&
    (accountSubscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE ||
      accountSubscriptionStatus === SUBSCRIPTION_STATUS.TRIALING ||
      accountSubscriptionStatus === SUBSCRIPTION_STATUS.PAST_DUE);
  const defaultSubscriptionPrice =
    accountPlan === SUBSCRIPTION_PLANS.MONTHLY ? userPriceOption.monthly : userPriceOption.yearly;
  const isMorY = accountPlan === SUBSCRIPTION_PLANS.MONTHLY ? "M" : "Y";

  const discount = !considerDiscount ? 0 : defaultSubscriptionPrice * (userDiscount / 100);
  return !isSubscriptionActive
    ? i18n.t(`accountSettings.userSubscritionText${isMorY}`).replace(/%s%/, defaultSubscriptionPrice - discount)
    : "";
};

export const useSheetData = ({ portfolio, category }) => {
  const { details: portfolioDetails, currency: portfolioCurrency } = portfolio;

  const getOwnershipValueForCustodian = useSelector(getOwnershipValueForCustodianReselectFn);

  const { sheets, sections, firstSheet, sheetTotalHashTable, sectionTotalHashTable } = useMemo(() => {
    let firstSheet = null;
    let sheetTotalHashTable = {};
    let sectionTotalHashTable = {};
    let currentSheetId = null;
    let currentSectionId = null;

    let sheetsArr = [];
    let sectionsArr = [];

    filterDataFromPortfolioDetails(
      portfolioDetails,
      row => {
        const ownership = getOwnershipValueForCustodian(row.id);
        let exchangeRate = getExchangeRate(getTickerUsingId(row.valueTickerId).shortName, portfolioCurrency);

        if (row.rate) {
          const rateParsed = JSON.parse(row.rate);
          const tickerForPrivateTicker = getTickerUsingId(rateParsed.t);
          exchangeRate = convertCurrency(rateParsed.p, tickerForPrivateTicker.shortName, portfolio.currency);
        }
        const computedRowValue = (calcCustodianOwnershipValue(row.value, ownership) || 0) * exchangeRate;

        if (row.value !== null) {
          sheetTotalHashTable[currentSheetId].total += computedRowValue;
          sheetTotalHashTable[currentSheetId].hasRowWithValue = true;

          sectionTotalHashTable[currentSectionId].total += computedRowValue;
          sectionTotalHashTable[currentSectionId].hasRowWithValue = true;
        }

        sectionTotalHashTable[currentSectionId].rows[row.id] = { ...row, ownership };

        if (!firstSheet && computedRowValue > 0) {
          firstSheet = sheetTotalHashTable[currentSheetId];
        }

        return true;
      },
      section => {
        currentSectionId = section.id;
        sectionTotalHashTable[currentSectionId] = {
          total: 0,
          hasRowWithValue: false,
          rows: {},
          ...section
        };
        sectionsArr.push(sectionTotalHashTable[currentSectionId]);
        sheetTotalHashTable[currentSheetId].sections[section.id] = section;

        return true;
      },
      sheet => {
        const isCorrectCategorySheet = sheet.category === category;

        if (!isCorrectCategorySheet) {
          return false;
        }

        currentSheetId = sheet.id;
        sheetTotalHashTable[currentSheetId] = {
          total: 0,
          hasRowWithValue: false,
          sections: {},
          ...sheet
        };

        sheetsArr.push(sheetTotalHashTable[currentSheetId]);

        return true;
      }
    );

    return {
      sheets: sheetsArr,
      sections: sectionsArr,
      firstSheet,
      sheetTotalHashTable,
      sectionTotalHashTable
    };

    //eslint-disable-next-line
  }, [category, portfolioDetails, portfolioCurrency]);

  return {
    sheets,
    sections,
    firstSheet,
    sheetTotalHashTable,
    sectionTotalHashTable
  };
};
