import React, { useEffect } from "react";
import { showRecaptchaV2Widget, verifyV3Token } from "@kubera/common";

const handleConditionalRenderRecaptchaV2 = action => {
  verifyV3Token(action).catch(() => {
    showRecaptchaV2Widget();
  });
};

const RecaptchaV2Render = ({ action }) => {
  useEffect(() => {
    const renderRecaptchaV2Element = async () => {
      await window.recaptchaV2LoadPromise;
      handleConditionalRenderRecaptchaV2(action);
    };

    renderRecaptchaV2Element();
  }, [action]);

  return <div id="html_element" style={{ marginTop: "5px" }}></div>;
};

export default RecaptchaV2Render;
